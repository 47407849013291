import { useEffect, useState } from 'react';
import style from './NoticeBox.module.css';
import { useNavigate } from "react-router-dom";

function NoticeBox({ title, date, summary, thumbURI, id }) {
	const navigate = useNavigate();
	return (
		<div onClick={() => { navigate(`/notice/service`); }} key={id} className={style.noticeBox}>
			<div className={style.noticeThumbBox}>
				<img className={style.noticeThumb} src={thumbURI}></img>
			</div>
			<div className={style.noticeTextsBox}>
				<h1 className={style.noticeTitle}>{title}</h1>
				<h2 className={style.noticeSummary}>{summary}</h2>
				<div className={style.noticeData}><p>{date}</p></div>
			</div>
		</div>
	);

}

export default NoticeBox;