import style from './Nav.module.css';
import { Link } from "react-router-dom";

//<Link to={`/notice`}><a className={style.link}>Notice</a></Link>

function Nav({ backColor }) {
	return (
		<div style={{ backgroundColor: backColor ? backColor : 'transparent' }} className={style.nav}>
			<div className={style.logoSection}>
				<Link to={`/`}><a className={style.logo}><img className={style.logoImage} src={`${process.env.PUBLIC_URL}/logo.png`} /></a></Link>
			</div>
			<div className={style.linkSection}>
				<a className={style.link} href="https://nslab.tech" target="_blank" rel="noopener noreferrer">NS Lab.</a>
				<a className={style.link} href="https://ictcrc.org" target="_blank" rel="noopener noreferrer">ICT-CRC</a>
				<a className={style.link} href="https://kumoh.ac.kr" target="_blank" rel="noopener noreferrer">kit</a>
				<a className={style.link} href="https://www.youtube.com/@ict1583" target="_blank" rel="noopener noreferrer">Youtube</a>
			</div>
			<div className={style.shortCutSection}>
				<Link to={`/signUp`}><a className={style.shortCut}>Sign Up</a></Link>
			</div>
		</div >
	);
}

export default Nav;