import { useState, useRef } from 'react'
import Nav from '../components/Nav'
import style from './SignUp.module.css'
import axios from 'axios'
import SHA256 from '../Encrypt'
import { useNavigate } from 'react-router-dom'



function SignUp() {
	const navigate = useNavigate();

	const [inputs, setInputs] = useState({
		id: '',
		password: '',
		email: '',
		nickname: ''
	})

	const [regex, setRegex] = useState(/^.*.{2,20}$/);
	const regexList = {
		id: /^[a-zA-Z0-9_-]{2,10}$/,
		password: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/,
		email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		nickname: /^[A-Za-z0-9]{4,12}$/
	}

	const Refs = {
		idRef: useRef(),
		passwordRef: useRef(),
		emailRef: useRef(),
		nicknameRef: useRef(),
		tooltipRef: useRef()
	}

	const [images, setImages] = useState({
		id_img: "invalid.png",
		password_img: "invalid.png",
		email_img: "invalid.png",
		nickname_img: "invalid.png"
	})

	const { id, password, email, nickname } = inputs;
	/**


		Functions


	**/


	const onChange = (e) => {
		const { value, name } = e.target;
		setInputs({
			...inputs,
			[name]: value
		})		

		Refs[name + 'Ref'].current.style = "visibility: visible;";

		setRegex(regexList[name])
		if (regex.test(value)) {
			setImages({
				...images,
				[name + '_img']: "valid.png"
			})
		}
		else {
			setImages({
				...images,
				[name + '_img']: "invalid.png"
			})
		}
	}

	const requestSignUp = async () => {

		const payload = {
			...inputs,
			passEncrypt: SHA256(inputs.password + '031EED9DB93CCBE94475244F24C1EDD5BF41E8F462015587091045D4960CADE8')
		}

		try {
			const response = await axios.post('api/signup', payload)
			if (response.data.result == true){
				alert("Sign Up Success")
				navigate('/')
			}
			else {
				alert("Sign Up Fail : " + response.data.detail.message)
			}
		} catch (e) {

		}
	}

	const onClick = () => {
		//console.log(inputs)
		if (images.id_img == 'valid.png' && images.password_img == 'valid.png' && images.email_img == 'valid.png' && images.nickname_img == 'valid.png') {

			requestSignUp()
		}
		else {

			alert("Invalid Format");
		}

	}

	const checkId = async () =>{
		try{
			const response = await axios.get(`api/findAUser?id=${id}`)

			setRegex(regexList.id)
			if (response.data.result == true && regex.test(id)){
				setImages({
					...images,
					id_img: "invalid.png"
				})
				Refs.tooltipRef.current.style =  "visibility: visible;"
			}
			else {
				setImages({
					...images,
					id_img: "valid.png"
				})
				Refs.tooltipRef.current.style =  "visibility: hidden;"
			}
		}
		catch(e){

		}
	}

	return (
		<div className='SignUp'>
			<Nav />
			<div className={style.articleBox}>
				<div className={style.stack}>
					<div className={style.articleCover}></div>
					<div className={style.articleContainer}>
						<div className={style.signUpConteiner}>
							<h2>Create your Creativia Account</h2>
							<form>
								<div className={style.tooltip}>
									<label>ID</label>
									<div className={style.inputbox}>
										<input onChange={onChange}  onBlur={checkId} name="id" type="text" placeholder="ID" value={id} autoComplete='off' />
										<img className={style.isvalid} src={`${process.env.PUBLIC_URL}/${images.id_img}`} ref={Refs.idRef} />
										<div className={style.tasktooltip} ref={Refs.tooltipRef}>This ID already exists.</div>
									</div>
									<span className={style.tooltiptext}>It must be composed of 2 to 10 letters of English and numerals.</span>
								</div>
								<div className={style.tooltip}>
									<label>Password</label>
									<div className={style.inputbox}>
										<input onChange={onChange} name="password" type="password" placeholder="Password" value={password} autoComplete='off'/>
										<img className={style.isvalid} src={`${process.env.PUBLIC_URL}/${images.password_img}`} ref={Refs.passwordRef} />
									</div>
									<span className={style.tooltiptext}>8 to 16 characters. One of lowercase, special characters must be included.</span>
								</div>
								<div className={style.tooltip}>
									<label>E-mail</label>
									<div className={style.inputbox}>
										<input onChange={onChange} name="email" type="text" placeholder="E-mail" value={email} autoComplete='off'/>
										<img className={style.isvalid} src={`${process.env.PUBLIC_URL}/${images.email_img}`} ref={Refs.emailRef} />
									</div>
									<span className={style.tooltiptext}>an e-mail format.</span>
								</div>
								<div className={style.tooltip}>
									<label>Nick name</label>
									<div className={style.inputbox}>
										<input onChange={onChange} name="nickname" type="text" placeholder="Nick name" value={nickname} autoComplete='off'/>
										<img className={style.isvalid} src={`${process.env.PUBLIC_URL}/${images.nickname_img}`} ref={Refs.nicknameRef} />
									</div>
									<span className={style.tooltiptext}>4-12 letters.</span>
								</div>
								<button onClick={onClick} type="button" className={style.Button}>Sign up</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}


export default SignUp