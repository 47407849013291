import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Home from "./routes/Home";
import SignUp from "./routes/SignUp";
import Notice from "./routes/Notice";
import Service from "./routes/Service";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={`/`} element={<Home />} />
        <Route path={`/signUp`} element={<SignUp />} />
        <Route path={`/notice`} element={<Notice />} />
        <Route path={`/notice/service`} element={<Service />} />
      </Routes>
    </Router>
  );
}

export default App;
