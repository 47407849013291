import { useEffect, useState } from 'react';
import style from './Notice.module.css';
import Nav from '../components/Nav';
import NoticeBox from '../components/NoticeBox';
import { Link } from "react-router-dom";

function Notice() {
	return (
		<div className='Notice'>
			<Nav backColor='#12182B' />
			<div className={style.noticeListContainer}>
				<NoticeBox
					title='Notice Title'
					date='2022-10-20'
					summary='Notice Summary'
					thumbURI='https://picsum.photos/200/300'
					id='1' />
			</div>
		</div >
	);

}

export default Notice;