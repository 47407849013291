import { useEffect, useState } from 'react';
import style from './Home.module.css';
import Nav from '../components/Nav';
import { useNavigate } from 'react-router-dom';


function Home() {

	const navigate = useNavigate();

	return (
		<div className='Home'>
			<Nav />
			<div className={style.articleBox}>
				<div className={style.stack}>
					<div className={style.articleImage}></div>
					<video className={style.articleVideo} src="./video.mp4" poster="true" autoPlay muted loop playsInline preload='none'></video>
					<div className={style.articleCover}></div>
					<div className={style.articleContainer}>
						<h1 className={style.articleTitle}>Hi Creativia!</h1>
						<h6>Creativia is an open R&D metaverse platform that will be developed with your participation.
							ICT Convergence Research Center of Kumoh National Institute of Technology and NSLab. are working together to develop Creativia.
							Creativia can be used in various fields such as education, research, and industry.</h6>
						<div className={style.Buttons}>
							<button className={style.Button}>Download Launcher</button>
							<button onClick={() => { navigate("/signUp"); }} className={style.Button}>Sign Up</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}

export default Home;