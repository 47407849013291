import { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import style from './Service.module.css';
import { useNavigate } from 'react-router-dom';


function Service() {

	return (
		<div className='service'>
			<Nav />
			<div className={style.articleContainer}>
				<div className={style.article}>
					<h1>Creativia Service Introduction</h1>
					<h2>Platform introduction</h2>
					<hr></hr>
					<h3>Virtuous cycle Metaverse platform created by everyone.</h3>
					<p>Creativia is a metaverse platform developed by NS Lab. and ICT Convergence Research Center of kit. A Virtuous cycle Metaverse platform that can implement everyone's ideas.</p>
					<img src={`${process.env.PUBLIC_URL}/service01.png`}></img>

					<h2>Platform utilization</h2>
					<hr></hr>
					<h3>We are providing customized contents, specialized functions and education services.</h3>

					<h3>[Online Exhibitoin]</h3>
					<p>The Unreal Engine-based Creativia platform can reproduce ultra-realistic graphics similar to reality, calculate sound according to the distance between the player and the sound source, and provide synesthetic sound services, while allowing free communication through chat and voice functions.</p>
					<h4>Metaverse information community operation</h4>
					<p>We aim to create an environment where we can acquire technical information such as game engine, 3d modeling, digital twin, and various technical knowledge necessary for the education and research industry through community operation.</p>
					<ul>
						<li><p>Rendering prop as cartoon ~ realistic</p></li>
						<li><p>Providing Synesthetic Sound Service through Sound Attenuation</p></li>
						<li><p>Providing non-face-to-face online services with chat and voice capabilities</p></li>
					</ul>
					<img src={`${process.env.PUBLIC_URL}/service_Exhibition.png`}></img>



					<h3>[Digital Twin]</h3>
					<p>We can provide a real-time monitoring system through data collection and signal processing by utilizing C++-based Unreal Engine Blueprints, and provide various services according to user purposes such as manufacturing, safety, and transportation.</p>
					<h4>Talent needed to implement a digital twin.</h4>
					<p>The kit ICT Convergence Specialized Research Center is working with master’s degree and doctoral’s degree in various industries necessary for digital twin.</p>
					<ul>
						<li><p>Real-time monitoring system through data collection and signal processing</p></li>
						<li><p>Providing key functions for user purpose</p></li>
					</ul>
					<img src={`${process.env.PUBLIC_URL}/service_digitalTwin.png`}></img>


					<h3>[Education Service]</h3>
					<p>Provide training services necessary for metaverse production, empowerment and basic digital twin training for various dustries.</p>
					<h4>Education Manual</h4>
					<p>It is a verified educational manual created by researchers of NS Lab. and ICT Convergence Research Center of kit, and is optimized for theoretical education and practice.</p>
					<ul>
						<li><p>Providing the training needed to create metaverse</p></li>
						<li><p>Providing digital twin foundation knowledge and training across different industries</p></li>
					</ul>
					<img src={`${process.env.PUBLIC_URL}/service_Edu.png`}></img>

					<h2>Partnership</h2>
					<hr></hr>
					<h3>Competitive Partnership Cooperation System</h3>
					<p>We are securing technological service competitiveness through MOUs and business agreements between various organizations.</p>
					<img src={`${process.env.PUBLIC_URL}/service_partner.png`}></img>

				</div>
			</div>
		</div >
	);

}

export default Service;